import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import CustomInput from "../../Components/Input/CustomInput";
import { GetServiceList } from "../../Function/Services/GetService";
import ServiceTable from "../../Components/Tables/ServiceTable";
import { SearchDebouncing } from "../../Function/SearchDebounce/Debouncing3";
import { API } from "../../Function/Config";

const Services = () => {
  const [serviceData, setServiceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const apiUrl = API.getService;

  const fetchServices = async () => {
    setLoading(true);
    try {
      let res = await GetServiceList(searchValue);
      setLoading(false);
      setServiceData(res.r);
      console.log(res);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = async (e) => {
    const v = e.target.value.trim();
    setSearchValue(v);
    try {
      await SearchDebouncing(v, setServiceData, apiUrl);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  return (
    <Box>
      <Navbar title={"Services List"} />
      <Box sx={{ width: "30%", m: 2 }}>
        <CustomInput
          onChange={handleSearch}
          showSearchIcon
          placeholder="Search"
          sx={{
            bgcolor: "#E9E6E8",
            width: "100%",
            borderRadius: "10px",
            border: "none",
            "& fieldset": {
              border: "none !important",
            },
          }}
        />
      </Box>
      <Box m={2}>
        <ServiceTable loading={loading} data={serviceData} />
      </Box>
    </Box>
  );
};

export default Services;
