import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { Alert, Box, Snackbar, Typography } from "@mui/material";
import CustomInput from "../../Components/Input/CustomInput";
import CustomButton from "../../Components/Button/CustomButton/CustomButton";
import { useFormik } from "formik";
import { handleAddFaq } from "../../Function/FAQ/AddFaq";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const AddFaq = () => {
  const location = useLocation();
  console.log(location);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
      id: null,
    },

    validationSchema: Yup.object({
      question: Yup.string().required("Question Is Required"),
      answer: Yup.string().required("Answer Is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let res = await handleAddFaq(values, id);
        if (res.s) {
          if(id){
            setSnackbarMessage("FAQ Edited successfully");
          }else{
            setSnackbarMessage("FAQ Added successfully");
          }
          setSnackbarOpen(true);
          await new Promise((resolve) => setTimeout(resolve, 1000));
          navigate("/faq");
        }

        resetForm();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); 
      }
    },
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleFaq = async () => {
    try {
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFaq();
    if (id) {
      formik.setValues({
        question: location.state.question,
        answer: location.state.answer,
        id: id,
      });
    }
  }, []);
  return (
    <Box sx={{ position: "relative" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success">{snackbarMessage}</Alert>
      </Snackbar>
      <Navbar title={"FAQs"} />
      <Box sx={{ p: 2, boxSizing: "border-box" }}>
        <CustomInput
          name="question"
          onChange={(e) => {
            const { value } = e.target;
            if (value.trimStart() !== value) {
              e.target.value = value.trimStart();
            }
            formik.handleChange(e);
          }}
          value={formik.values.question}
          sx={{ width: "100%" }}
          placeholder="Question"
          onBlur={formik.handleBlur}
          onKeyUp={formik.handleBlur}
          invalid={
            formik.touched.question && formik.errors.question ? true : false
          }
        />
        {formik.touched.question && formik.errors.question ? (
          <Typography
            sx={{ color: "red", textAlign: "left", fontSize: "14px" }}
          >
            {formik.errors.question}
          </Typography>
        ) : null}
        <CustomInput
          name="answer"
          onChange={(e) => {
            const { value } = e.target;
            if (value.trimStart() !== value) {
              e.target.value = value.trimStart();
            }
            formik.handleChange(e);
          }}
          value={formik.values.answer}
          sx={{ width: "100%", mt: "10px" }}
          multiline
          rows={4}
          placeholder="Answer"
        />{" "}
        {formik.touched.answer && formik.errors.answer ? (
          <Typography
            sx={{ color: "red", textAlign: "left", fontSize: "14px" }}
          >
            {formik.errors.answer}
          </Typography>
        ) : null}
        <Box>
          <Box
            sx={{
              display: "flex",
              mt: "10px",
              justifyContent: "end",
              gap: "10px",
            }}
          >
            <CustomButton
              sx={{
                width: "100px",
                bgcolor: "#fff",
                color: "#000",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                  bgcolor: "#fff",
                  color: "#000",
                },
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              disabled={!(formik.dirty && formik.isValid) || loading}
              onClick={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
              sx={{
                width: "100px",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
                "&:disabled": {
                  color: "gray",
                },
              }}
            >
              {id ? "Edit" : "Add"}
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddFaq;
