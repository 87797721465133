import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { handleAddInterest } from "../../Function/Interest/AddInterest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CustomInput from "../../Components/Input/CustomInput";
import upload from "../../utils/image/upload.png";
import bin from "../../utils/image/bin.png";
import CustomButton from "../../Components/Button/CustomButton/CustomButton";
import { GetInterest } from "../../Function/Interest/GetInterest";
import ConfirmModal from "../../Components/Modal/ConfirmModal";
import { handleEditInterest } from "../../Function/Interest/EditInterest";
import { GetAdvertise } from "../../Function/Advertise/GetAdvertise";
import { AddAdvertise } from "../../Function/Advertise/AddAdvertise";
import { handleEditAdvertise } from "../../Function/Advertise/Deleteadvertise";
import { SketchPicker } from "react-color";
const Advertise = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState("");
  const [type, setType] = useState("Add");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      media: null,
      id: null,
      filetype: "",
      bg_color: "",
    },
    validationSchema: Yup.object({
      media: Yup.string().required("Please Upload Image"),
    }),

    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let res = await AddAdvertise(values);
        if (res.s) {
          setType("Edit");
          setSnackbarMessage("Advertise Added successfully");
          resetForm();
          formik.setFieldValue("media", "");
          setSnackbarOpen(true);
          setImagePreview(null);
          handleAdvertiseData();
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        setType("Add");
        resetForm();
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  const handleColorChange = (color) => {
    formik.setFieldValue("bg_color", color.hex);
    formik.setFieldValue("filetype", 3);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const maxSize = 3 * 1024 * 1024 * 1024;
      if (selectedFile.size <= maxSize) {
        const imageURL = URL.createObjectURL(selectedFile);
        setImagePreview(imageURL);

        const fileType = selectedFile.type.split("/")[0];

        if (fileType === "image") {
          formik.setFieldValue("media", selectedFile);
          formik.setFieldValue("filetype", 1);
        } else if (fileType === "video") {
          formik.setFieldValue("media", selectedFile);
          formik.setFieldValue("filetype", 2);
        }
      } else {
        // File size exceeds the limit
        alert("File size must be less than 2 MB");
        // Optionally, clear the file input to allow the user to select a different file
        e.target.value = null;
      }
    }
  };

  const handleChange = (e) => {
    const text = e.target.value;
    formik.setFieldValue("media", text);
    formik.setFieldValue("filetype", 3);
  };
  const onClose = () => {
    setIsModalOpen(false);
  };
  const handleDeleteModal = (i) => {
    setIsModalOpen(true);
    setId(i);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleRemoveImage = () => {
    setImagePreview(null);
    formik.setFieldValue("thumb", null);
    formik.setFieldValue("filetype", null);
  };
  const handleAdvertiseData = async () => {
    setLoading(true);
    try {
      let res = await GetAdvertise();
      setData(res.r);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      let res = await handleEditAdvertise(id);
      setSnackbarMessage("Advertise Deleted successfully");
      setSnackbarOpen(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));

      onClose();
      handleAdvertiseData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (e) => {
    setType("Edit");
    formik.setValues({ interest: e.intrest, thumb: e.thumb, id: e.id });
    setImagePreview(`https://api.proxx.in/uploads/interest/${e.thumb}`);
  };

  useEffect(() => {
    handleAdvertiseData();
  }, []);
  console.log("values", formik.values);
  return (
    <Box>
      <Navbar title={"Advertise"} />
      <ConfirmModal
        id={id}
        setIsModalOpen={setIsModalOpen}
        handleDelete={handleDelete}
        open={isModalOpen}
        onClose={onClose}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success">{snackbarMessage}</Alert>
      </Snackbar>
      <Box
        sx={{ display: "flex", height: "calc(100vh - 60px)", overflow: "auto" }}
      >
        <Box sx={{ width: "50%", p: 2 }}>
          <Box>
            <Typography textAlign={"left"} fontWeight={600}>
              Add Advertise
            </Typography>
            {imagePreview ? (
              <Box
                sx={{
                  borderRadius: "15px",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: "3px",
                  mt: "5px",
                  border: "2px solid gray",
                  mt: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    borderRadius: "15px",
                    p: 2,
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      height: "130px",
                      m: "auto",
                      position: "relative",
                    }}
                  >
                    <img
                      width={"100%"}
                      height={"100%"}
                      src={imagePreview}
                      alt="preview"
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: "white",
                      }}
                      onClick={handleRemoveImage}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  borderRadius: "15px",
                  boxSizing: "border-box",
                  // display: "flex",
                  display: formik.values.filetype == 3 ? "none" : "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: "3px",
                  mt: "5px",
                  border: "2px solid gray",
                  mt: "10px",
                }}
              >
                <label
                  style={{
                    display: "block",
                    width: "100%",
                    borderRadius: "10px",
                    height: "150px",
                    cursor: "pointer",
                    textAlign: "center",
                    lineHeight: "150px",
                    display: imagePreview ? "flex" : "block",
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        width: "50px",
                        height: "50px",
                        m: "auto",
                      }}
                    >
                      <img
                        width={"100%"}
                        height={"100%"}
                        src={upload}
                        alt="preview"
                      />
                    </Box>
                    <Typography sx={{ mt: "40px" }}>
                      Upload a Photo or Video
                    </Typography>
                  </Box>

                  <input
                    type="file"
                    accept="image/*,video/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </label>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              width: "50%",
              m: "auto",
              mt: "10px",
              display: formik.values.filetype == 3 ? "none" : "flex",
            }}
          >
            <CustomButton
              sx={{
                "&:disabled": {
                  color: "#fff",
                  bgcolor: "gray",
                },
              }}
              disabled={loading}
              onClick={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
            >
              {type}
            </CustomButton>
          </Box>
          <Divider sx={{ bgcolor: "#000", my: "10px" }} />
          <Box
            sx={{
              mt: "5px",
              display:
                formik.values.filetype == 1
                  ? "none"
                  : formik.values.filetype == 2
                  ? "none"
                  : "flex",
              gap: "10px",
              textAlign: "start",
            }}
          >
            <Box>
              {" "}
              <Typography color={"#000"}>Story Text</Typography>
              <CustomInput
                value={
                  formik.values.media === !null ? formik.values.media : null
                }
                onChange={handleChange}
                sx={{ width: "100%", "& .MuiInputBase-input": { p: 1 } }}
                multiline
                rows={5}
                placeholder={"Text Story"}
              />{" "}
            </Box>
            <Box
              sx={{
                borderRadius: "15px",
                boxSizing: "border-box",
                justifyContent: "center",
                alignItems: "center",
                p: "5px",
                background: `#fff`,
                textAlign: "start",
              }}
            >
              <Typography color={"#000"}>Story Background Color</Typography>
              <SketchPicker
                borderRadius="15px"
                color={formik.values.bg_color || "#1B4DC0"}
                onChange={handleColorChange}
              />
            </Box>
          </Box>{" "}
          <Box
            sx={{
              width: "50%",
              m: "auto",
              mt: "10px",
              display:
                formik.values.filetype == 1
                  ? "none"
                  : formik.values.filetype == 2
                  ? "none"
                  : "block",
            }}
          >
            <CustomButton
              sx={{
                "&:disabled": {
                  color: "#fff",
                  bgcolor: "gray",
                },
              }}
              disabled={loading}
              onClick={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
            >
              {type}
            </CustomButton>
          </Box>
        </Box>
        {/* interest list */}
        <Box sx={{ width: "50%", p: 2, maxHeight: "80vh", overflow: "auto" }}>
          <Typography textAlign={"left"} fontWeight={600}>
            Advertise List
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box sx={{ maxHeight: "70vh", overflow: "auto" }}>
              {data?.map((e, i) => (
                <Box
                  key={i}
                  sx={{
                    border: "1px solid gray",
                    borderRadius: "10px",
                    textAlign: "left",
                    p: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: "5px",
                    boxSizing: "border-box",
                    height: e.media_type == 2 ? "125px" : "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: e.media_type === 3 ? "100%" : "50%",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title={e.media}>
                      <Typography
                        sx={{
                          // width: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          // ":hover": {
                          //   whiteSpace: "normal",
                          // },
                        }}
                      >
                        {e.media_type == 3 ? e.media : ""}
                      </Typography>
                    </Tooltip>
                    <Box
                      sx={{
                        height: e.media_type == 3 ? "0" : "100px",
                        width: e.media_type == 3 ? "150px" : "150px",
                      }}
                    >
                      {e.media_type == 1 ? (
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "5px",
                            objectFit: "cover",
                          }}
                          src={`https://api.proxx.in/uploads/user/story/${e.media}`}
                          alt="preview"
                        />
                      ) : e.media_type == 2 ? (
                        <Box sx={{ width: "200px", height: "100px" }}>
                          <video
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "5px",
                              objectFit: "cover",
                            }}
                            src={`https://api.proxx.in/uploads/user/story/${e.media}`}
                            controls
                            alt="preview"
                          />{" "}
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <Box
                      onClick={() => handleDeleteModal(e.id)}
                      sx={{ width: "20px", height: "20px", cursor: "pointer" }}
                    >
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={bin}
                        alt="preview"
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Advertise;
