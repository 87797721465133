import { Box, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { sidebarArray } from "./Sidebar.dto";
import { Link, useLocation, useNavigate } from "react-router-dom";
import exit from "../../utils/image/exit.png";
import CustomButton from "../Button/CustomButton/CustomButton";
import logouticon from "../../utils/image/exit.png";

const Sidebar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const location = useLocation().pathname;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleLogoutConfirmed = () => {
    navigate("/login");
    localStorage.removeItem("ProXAdmin");
    setOpen(false);
  };
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          height: "100vh",
          bgcolor: "#1D0523",
          color: "#fff",
          overflow: "scroll",
          // display: { xs: "none", lg: "flex" },
          // justifyContent: "center",
          width: "260px",
          position: "sticky",
          top: 0,
        }}
      >
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: 24,
              borderRadius: "20px",
              outline: "none",
              p: 4,
              bgcolor: "white",
            }}
          >
            <Box sx={{ m: "auto", width: "50px", height: "50px" }}>
              <img
                width={"100%"}
                height={"100%"}
                src={logouticon}
                alt="preview"
              />
            </Box>
            <Typography
              textAlign={"center"}
              mt={"10px"}
              variant="h6"
              component="h2"
            >
              Logout
            </Typography>
            <Typography sx={{ opacity: "70%" }}>
              Are you sure you want to logout?
            </Typography>
            <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
              <CustomButton
                sx={{
                  bgcolor: "#1B4DC0",
                  "&:hover": {
                    background: "#1B4DC0",
                  },
                  borderRadius: "20px",
                  p: "15px",
                  paddingX: "30px",
                }}
                onClick={handleLogoutConfirmed}
              >
                Yes
              </CustomButton>
              <CustomButton
                sx={{
                  bgcolor: "#7B7B7B",
                  "&:hover": {
                    background: "#7B7B7B",
                  },
                  borderRadius: "20px",
                  p: "15px",
                  paddingX: "30px",
                }}
                onClick={handleClose}
              >
                No
              </CustomButton>
            </Box>
          </Box>
        </Modal>
        <Box
          sx={{
            height: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"50px"}>ProX</Typography>
        </Box>
        <Box>
          {sidebarArray?.map((e, i) => (
            <Link
              key={i}
              style={{ color: "#FFF", textDecoration: "none" }}
              to={e.path}
            >
              <Box
                sx={{
                  p: 2,
                  color: location == e.path ? "#FFF" : "#8E8E8E",
                  bgcolor: location == e.path ? "#5B3D63" : "",
                  fontWeight: location == e.path ? 700 : 400,
                  cursor: "pointer",
                  transition: "opacity 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                    outline: location == e.path ? "none" : "1px solid",
                  },
                }}
              >
                <Typography>{e.title}</Typography>
              </Box>
            </Link>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            alignItem: "center",
            justifyContent: "center",
            p: 2,
          }}
        >
          <Box sx={{ height: "20px", width: "20px" }}>
            <img height={"100%"} width={"100%"} src={exit} alt="logo" />
          </Box>
          <Typography
            sx={{
              textAlign: "left",
              fontFamily: "inter",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "20px",
              cursor: "pointer",
              color: "#737373",
              // mt: "5px",
            }}
            onClick={handleClickOpen}
          >
            Log Out
          </Typography>
        </Box>
      </Box>{" "}
    </Box>
  );
};

export default Sidebar;
