import { Box, CircularProgress, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomInput from "../../Components/Input/CustomInput";
import CustomButton from "../../Components/Button/CustomButton/CustomButton";
import { AddLinks } from "../../Function/AddLinks/AddLinks";
import { GetLinks } from "../../Function/AddLinks/GetLinks";
import { toast } from "react-toastify";
const Links = () => {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fb_link: null,
      insta_link: null,
      twitter_link: null,
      tiktok_link: null,
    },
    validationSchema: Yup.object({
      fb_link: Yup.string()
        .trim()
        .url("Facebook link must be a valid URL")
        .required("Facebook link is required"),
      insta_link: Yup.string()
        .trim()
        .url("Instagram link must be a valid URL")
        .required("Instagram link is required"),
      twitter_link: Yup.string()
        .trim()
        .url("Twitter link must be a valid URL")
        .required("Twitter link is required"),
      tiktok_link: Yup.string()
        .trim()
        .url("Tiktok link must be a valid URL")
        .required("Tiktok link is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let res = await AddLinks(values);
        if (res.s) {
          toast.success("Links Added successfully");
          GetSocialLinks();
        }
        setLoading(false);
        resetForm();
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  const GetSocialLinks = async () => {
    setLoading(true);
    try {
      let res = await GetLinks();
      console.log(res.r);
      formik.setFieldValue("fb_link", res.r.fb_link);
      formik.setFieldValue("tiktok_link", res.r.tiktok_link);
      formik.setFieldValue("insta_link", res.r.insta_link);
      formik.setFieldValue("twitter_link", res.r.twitter_link);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    GetSocialLinks();
  }, []);
  return (
    <Box>
      <Navbar title={"Add Links"} />
      <Box>
        {loading ? (
          <CircularProgress sx={{ mt: "22%" }} />
        ) : (
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "50%",
                justifyContent: "space-between",
                m: "auto",
              }}
            >
              <Typography>Facebook link :</Typography>
              <CustomInput
                name="fb_link"
                onChange={formik.handleChange}
                value={formik.values.fb_link}
                sx={{ width: "60%", mt: "10px" }}
                placeholder="Enter Facebook Link"
              />
              {formik.touched.fb_link && formik.errors.fb_link ? (
                <Typography
                  sx={{
                    color: "red",
                    width: "40%",
                    m: "auto",
                    fontSize: "14px",
                    textAlign: "left",
                  }}
                >
                  {formik.errors.fb_link}
                </Typography>
              ) : null}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "50%",
                justifyContent: "space-between",
                m: "auto",
              }}
            >
              <Typography>Instagram link :</Typography>
              <CustomInput
                name="insta_link"
                onChange={formik.handleChange}
                value={formik.values.insta_link}
                sx={{ width: "60%", mt: "10px" }}
                placeholder="Enter instagram Link"
              />{" "}
              {formik.touched.insta_link && formik.errors.insta_link ? (
                <Typography
                  sx={{
                    color: "red",
                    width: "40%",
                    m: "auto",
                    fontSize: "14px",
                    textAlign: "left",
                  }}
                >
                  {formik.errors.insta_link}
                </Typography>
              ) : null}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "50%",
                justifyContent: "space-between",
                m: "auto",
              }}
            >
              <Typography>Twitter link :</Typography>
              <CustomInput
                name="twitter_link"
                onChange={formik.handleChange}
                value={formik.values.twitter_link}
                sx={{ width: "60%", mt: "10px" }}
                placeholder="Enter Twitter Link"
              />{" "}
              {formik.touched.twitter_link && formik.errors.twitter_link ? (
                <Typography
                  sx={{
                    color: "red",
                    width: "40%",
                    m: "auto",
                    fontSize: "14px",
                    textAlign: "left",
                  }}
                >
                  {formik.errors.twitter_link}
                </Typography>
              ) : null}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "50%",
                justifyContent: "space-between",
                m: "auto",
              }}
            >
              <Typography>Tiktok link :</Typography>
              <CustomInput
                name="tiktok_link"
                onChange={formik.handleChange}
                value={formik.values.tiktok_link}
                sx={{ width: "60%", mt: "10px" }}
                placeholder="Enter Tiktok Link"
              />{" "}
              {formik.touched.tiktok_link && formik.errors.tiktok_link ? (
                <Typography
                  sx={{
                    color: "red",
                    width: "40%",
                    m: "auto",
                    fontSize: "14px",
                    textAlign: "left",
                  }}
                >
                  {formik.errors.tiktok_link}
                </Typography>
              ) : null}
            </Box>
            <Box>
              <CustomButton
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  return false;
                }}
                sx={{ width: "50%", mt: "10px" }}
              >
                Add
              </CustomButton>
            </Box>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snackbarOpen}
              autoHideDuration={1000}
              onClose={handleCloseSnackbar}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Links;
