import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

const Navbar = ({ title, details }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "#E9E6E8",
        p: 2,
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ArrowBackIosIcon
          onClick={() => navigate(`/${details}`)}
          sx={{ display: details ? "block" : "none", cursor: "pointer" }}
        />
        <Typography fontWeight={600}>{title}</Typography>
      </Box>
      {/* <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Avatar src={profile} alt="preview" />
        <Typography>John Singh</Typography>
      </Box> */}
    </Box>
  );
};

export default Navbar;
