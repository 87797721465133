import { Box } from "@mui/material";
import { react, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetUserDetails } from "../../Function/User/GetUserDetails";

import Navbar from "../../Components/Navbar/Navbar";
import MyEvents from "../../Components/MapEventBox/MyEvents";

const EventsJoined = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  let id = location.state.id;

  const handleJoined = async () => {
    try {
      let res = await GetUserDetails(id);
      setData(res?.r);
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    handleJoined();
  }, []);
  return (
    <>
      <Box sx={{ height: "100vh" }}>
        <Navbar title={"Joined Events"} details={"user"} />
        <Box
          sx={{
            p: 2,
            bgcolor: "#00000033",
            height: "calc(100vh - 59px)",
            overflow: "auto",
          }}
        >
          {data ? <MyEvents events={data} /> : " "}
        </Box>
      </Box>
    </>
  );
};

export default EventsJoined;
