import { API, API_POST } from "../Config";

export const handleCancelEvent = (id, reason) => {
  const f = new FormData();

  f.append("id", id);
  f.append("cancel_reason", reason);
  f.append("status", -1);

  return API_POST(API.cancelevent, f);
};
