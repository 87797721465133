import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomButton from "../../Components/Button/CustomButton/CustomButton";
import { Alert, Snackbar } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UpdateTerms } from "../../Function/Terms/AddTerms";
import { GetTerms } from "../../Function/Terms/GetTerms";

const TermCondition = () => {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleDragStart = (event) => {
    event.preventDefault();
  };
  const formats = [
    "header",
    "font",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "list",
    "bullet",
    "link",
  ];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      term: "",
    },
    validationSchema: Yup.object({
      term: Yup.string().required("Term Is Required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let res = await UpdateTerms(values);
        if (res.s) {
          setSnackbarMessage("Terms & Condition Updated successfully");
          setSnackbarOpen(true);
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleTerms = async () => {
    try {
      let res = await GetTerms();
      if (res.s) {
        console.log(res.r.data);
        formik.setFieldValue("term", res.r.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleTerms();
  }, []);

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success">{snackbarMessage}</Alert>
      </Snackbar>

      <Box
        sx={{
          width: "100%",
          textAlign: "left",
          borderRadius: "10px",
          boxSizing: "border-box",
          p: "2px",
          background: `linear-gradient(165deg,#1B4DC0E5, #eee)`,
          mt: "15px",
          "& .ql-container": {
            minHeight: "200px",
            maxHeight: "60vh",
            overflow: "auto",
            resize: "both", 
            color: "#000",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            backgroundColor: "#fff",
          },
          "& .ql-toolbar": {
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            zIndex: 999,
            backgroundColor: "#fff",
          },
        }}
      >
        <ReactQuill
          name="term"
          style={{
            width: "100%",
            textAlign: "left",
            borderRadius: "15px",
          }}
          formats={formats}
          onDragStart={handleDragStart}
          theme="snow"
          onChange={(e) => {
            formik.setFieldValue("term", e);
            formik.handleChange(e);
          }}
          value={formik.values.term}
          modules={{
            toolbar: {
              container: [
                [{ header: "1" }, { header: "2" }],
                [{ font: [] }],
                ["bold", "italic", "underline", "strike"],
                [{ color: [] }, { background: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link"],
                ["clean"],
              ],
            },
          }}
        />
      </Box>

      <Box sx={{ p: 1, display: "flex", justifyContent: "end", mt: "10px" }}>
        <CustomButton
          disabled={!(formik.dirty && formik.isValid)}
          onClick={formik.handleSubmit}
          sx={{
            width: "20%",
            "&:disabled": {
              color: "#fff",
              bgcolor: "gray",
            },
          }}
        >
          Submit
        </CustomButton>
      </Box>
    </Box>
  );
};

export default TermCondition;
