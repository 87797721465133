const sidebarArray = [
  { id: 1, title: "Dashboard", path: "/" },
  { id: 2, title: "Events", path: "/events" },
  { id: 3, title: "Services", path: "/services" },
  { id: 4, title: "Payment", path: "/payment" },
  { id: 5, title: "User", path: "/user" },
  { id: 6, title: "Interest", path: "/interest" },
  { id: 7, title: "Boost", path: "/boost" },
  { id: 8, title: "Legal", path: "/legal" },
  { id: 9, title: "Blog", path: "/blog" },
  { id: 10, title: "FAQs", path: "/faq" },
  { id: 11, title: "Advertise", path: "/advertise" },
  { id: 12, title: "Links", path: "/links" },
];

export { sidebarArray };
