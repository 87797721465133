import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { GetUserDetails } from "../../Function/User/GetUserDetails";
import bin from "../../utils/image/bin.png";
import block from "../../utils/image/block.png";
import ConfirmModal from "../../Components/Modal/ConfirmModal";
import { DeleteUser } from "../../Function/User/GetUserDelete";

const UserDetails = () => {
  const [data, setData] = useState({});
  const { id } = useParams();
  const [type, setType] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleConfirm = (value) => {
    setOpen(true);
    setType(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      let res = await DeleteUser(id, type);
      setOpen(false);
      handleUserDetails();
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserDetails = async () => {
    try {
      let res = await GetUserDetails(id);
      setData(res.r);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCreated = (id) => {
    navigate("/eventscreated", { state: { id } });
  };

  const handleJoined = (id) => {
    navigate("/eventsjoined", { state: { id } });
  };

  const handleExperience = (id) => {
    navigate("/eventexperience", { state: { id } });
  };

  const handleService = (e) => {
    navigate("/servicecreated", { state: e.created_services });
  };

  useEffect(() => {
    handleUserDetails();
  }, []);

  return (
    <Box sx={{ height: "90vh", overflow: "hidden" }}>
      <Navbar title={"User Details"} details={"user"} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          boxSizing: "border-box",
          px: 2,
          py: 1,
          gap: "10px",
        }}
      >
        <Box onClick={() => handleConfirm(-2)} sx={{ width: "25px" }}>
          <img
            style={{ width: "100%", height: "100", cursor: "pointer" }}
            src={bin}
            alt="preview"
          />
        </Box>
        <Box onClick={() => handleConfirm(-1)} sx={{ width: "25px" }}>
          <img
            style={{ width: "100%", height: "100", cursor: "pointer" }}
            src={block}
            alt="preview"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          height: `calc(100vh - 60px)`,
          boxSizing: "border-box",
          p: 1,
        }}
      >
        {" "}
        <Box sx={{ width: "100%", height: "100%" }}>
          <Box
            sx={{
              height: "65%",
              px: 1,
              display: "flex",
              alignItems: "center",
              boxSizing: "border-box",
              gap: "5px",
            }}
          >
            <Box
              sx={{
                border: "1px solid",
                borderRadius: "10px 10px 0px 0px",
                height: "100%",
                width: "25%",
                textAlign: "start",
              }}
            >
              <Box sx={{ height: "50%" }}>
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "10px 10px 0px 0px",
                  }}
                  src={`https://api.proxx.in/uploads/user/images/${
                    data?.b_logo || data?.profile_img
                  } `}
                  alt=""
                />
              </Box>
              <Box sx={{ p: 1, boxSizing: "border-box" }}>
                <Typography fontSize={"14px"}>User Name</Typography>
                <Typography fontWeight={600}>
                  {data?.user_name || data?.b_name}
                </Typography>
                <Divider sx={{ bgcolor: "#000" }} />

                <Typography fontSize={"14px"}>email</Typography>
                <Typography sx={{ wordBreak: "break-all" }} fontWeight={600}>
                  {data?.email}
                </Typography>
                <Divider sx={{ bgcolor: "#000" }} />

                <Typography fontSize={"14px"}>Phone Number</Typography>
                <Typography fontWeight={600}>
                  {data.phno || data.b_name}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                height: "100%",
                width: "75%",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                boxSizing: "border-box",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                  width: "50%",
                  gap: "5px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    border: "1px solid",
                    borderRadius: "5px",
                    textAlign: "start",
                    boxSizing: "border-box",
                    p: 1,
                  }}
                >
                  <Typography>Address</Typography>
                  <Typography sx={{ fontWeight: 600, fontSize: "22px" }}>
                    {data?.location}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    border: "1px solid",
                    borderRadius: "5px",
                    boxSizing: "border-box",
                    p: 1,
                    textAlign: "start",
                  }}
                >
                  <Typography>User Interest</Typography>
                  <Typography sx={{ fontWeight: 600 }}>
                    {" "}
                    {data?.interest?.length
                      ? data?.interest?.map((e) => e.intrest + ", ")
                      : "No Interest Added"}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                  width: "50%",
                  gap: "5px",
                  textAlign: "start",
                }}
              >
                <Box
                  onClick={() => handleCreated(data?.id)}
                  sx={{
                    width: "100%",
                    height: "50%",
                    border: "1px solid",
                    borderRadius: "5px",
                    boxSizing: "border-box",
                    p: 1,
                    cursor: "pointer",
                  }}
                >
                  <Typography fontSize={"14px"}>Event Created</Typography>
                  <Typography sx={{ fontWeight: 600, fontSize: "22px" }}>
                    Created - {data?.created_count}
                  </Typography>
                </Box>
                <Box
                  onClick={() => handleService(data)}
                  sx={{
                    width: "100%",
                    height: "50%",
                    border: "1px solid",
                    borderRadius: "5px",
                    p: 1,
                    cursor: "pointer",
                    textAlign: "start",
                  }}
                >
                  <Typography>Service Created</Typography>
                  <Typography sx={{ fontWeight: 600, fontSize: "22px" }}>
                    {data?.created_services?.length}
                  </Typography>
                </Box>
              </Box>{" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                  width: "50%",
                  gap: "5px",
                  textAlign: "start",
                }}
              >
                <Box
                  onClick={() => handleJoined(data?.id)}
                  sx={{
                    width: "100%",
                    height: "50%",
                    border: "1px solid",
                    borderRadius: "5px",
                    boxSizing: "border-box",
                    p: 1,
                    cursor: "pointer",
                  }}
                >
                  <Typography fontSize={"14px"}>Event Joined</Typography>

                  <Typography sx={{ fontWeight: 600, fontSize: "25px" }}>
                    Joined - {data?.joined_count}
                  </Typography>
                </Box>
                <Box
                  onClick={() => handleExperience(data?.id)}
                  sx={{
                    width: "100%",
                    height: "50%",
                    border: "1px solid",
                    borderRadius: "5px",
                    p: 1,
                    cursor: "pointer",
                    textAlign: "start",
                  }}
                >
                  <Typography>Event Experience</Typography>
                  <Typography sx={{ fontWeight: 600 }}>
                    {data.experience || "No Experience Added"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <ConfirmModal
        id={id}
        handleDelete={handleDelete}
        open={open}
        onClose={handleClose}
      />
    </Box>
  );
};

export default UserDetails;
