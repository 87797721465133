import { Box, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";

const ServiceCreated = () => {
  const location = useLocation();
  console.log(location.state, "location");
  let data = location.state;
  return (
    <>
      <Navbar title={"Created Service"} details={"user"} />
      <Box
        sx={{
          display: "flex",
          p: 1,
          boxSizing: "border-box",
          gap: "10px",
        }}
      >
        {data?.map((e, i) => (
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              border: "1px solid",
              borderRadius: "15px",
              width: "300px",
              overflow: "hidden",
            }}
          >
            <Box sx={{ width: "100px", height: "150px" }}>
              <img
                style={{ width: "100%", height: "100%" }}
                src={`https://api.proxx.in/uploads/user/images/${e.img}`}
                alt="preview"
              />
            </Box>
            <Box sx={{ textAlign: "left" }}>
              <Typography>
                <b>Name : </b>
                {e.name}
              </Typography>
              <Typography>
                <b>Description : </b>
                {e.description}
              </Typography>
              <Typography>
                <b>Price : </b>
                {e.price}
              </Typography>
              <Typography>
                <b>City : </b>
                {e.city}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default ServiceCreated;
