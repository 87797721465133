import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import CustomInput from "../../Components/Input/CustomInput";
import { SearchDebouncing } from "../../Function/SearchDebounce/Debouncing3";
import { API } from "../../Function/Config";
import BoostTable from "../../Components/Tables/BoostTable";
import { GetBoostList } from "../../Function/Boost/Boost";

const Boost = () => {
  const [boostData, setBoostData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const apiUrl = API.getService;

  const fetchBoost = async () => {
    setLoading(true);
    try {
      let res = await GetBoostList(searchValue);
      setLoading(false);
      setBoostData(res.r);
      console.log(res);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  console.log("payment" + boostData);
  
  const handleSearch = async (e) => {
    const v = e.target.value.trim();
    setSearchValue(v);
    try {
      await SearchDebouncing(v, setBoostData, apiUrl);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBoost();
  }, []);

  return (
    <Box>
      <Navbar title={"Boost List"} />
      {/* <Box sx={{ width: "30%", m: 2 }}>
        <CustomInput
          onChange={handleSearch}
          showSearchIcon
          placeholder="Search"
          sx={{
            bgcolor: "#E9E6E8",
            width: "100%",
            borderRadius: "10px",
            border: "none",
            "& fieldset": {
              border: "none !important",
            },
          }}
        />
      </Box> */}
      <Box m={2}>
        <BoostTable loading={loading} data={boostData} />
      </Box>
    </Box>
  );
};

export default Boost;
