import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import background from "../../utils/image/mainBG.png";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../../Components/Button/CustomButton/CustomButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { API, API_POST } from "../../Function/Config";
import { useFormik } from "formik";
import * as Yup from "yup";
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().required("Email Is Required").email(),
      password: Yup.string()
        .required("Password Is Required")
        .min(6, "Password must be at least 6 characters")
        .max(20, "Password must be at most 20 characters"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const res = await API_POST(API.login, values);
        if (res.s) {
          localStorage.setItem("ProXAdmin", JSON.stringify(res.r));
          handleSnackbar("success", "Login Successful");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          handleSnackbar("error", "Something  went wrong");
        }
        resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSnackbar = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleClickshowPassword = () => setShowPassword((show) => !show);

  // const handleSubmit = async () => {
  //   setLoading(true);

  //   const f = new FormData();
  //   f.append("email", formData?.email);
  //   f.append("password", formData?.password);
  //   try {
  //     const res = await API_POST(API.login, f);
  //     console.log(res);
  //     setLoading(false);
  //     if (res.s) {
  //       localStorage.setItem("ProXAdmin", JSON.stringify(res.r));
  //       handleSnackbar("success", "Login Successful");
  //       setTimeout(() => {
  //         navigate("/");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     alert("wrong credentials");
  //     setLoading(false);
  //     console.error("Login failed:", error);
  //   }
  // };

  const handleFieldChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box
      sx={{
        height: "100vh",
        objectFit: "cover",
        fill: "Background",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "cover",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: { sm: "60%", md: "450px", xl: "500px" },
          padding: { xs: "20px", md: "35px", xl: "45px" },
          borderRadius: "20px",
          bgcolor: "#F5F5F5",
          m: "auto",
          boxShadow: "10px 10px 26px 0px #0000001A",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: { xs: "20px", md: "35px", xl: "42px" },
            fontWeight: "700",
            lineHeight: "25px",
            textAlign: "left",
          }}
        >
          Welcome back!
        </Typography>
        <Box
          sx={{
            textAlign: "left",
            mt: 2,
          }}
        >
          <Typography
            sx={{
              fontFamily: "inter",
              fontSize: { xs: "14px", md: "16px", xl: "22px" },
              fontWeight: "400",
              lineHeight: "22.4px",
              textAlign: "left",
              color: "#000000E5",
            }}
          >
            Enter Your Email & Password to Login
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "10px",
            textAlign: "left",
          }}
        >
          <Typography sx={{ mt: "10px" }}>Email</Typography>
          <TextField
            name="email"
            onChange={(e) => {
              const { value } = e.target;
              if (value.trimStart() !== value) {
                e.target.value = value.trimStart();
              }
              formik.handleChange(e);
            }}
            value={formik.values.email}
            type="email"
            placeholder="Email Address"
            onBlur={formik.handleBlur}
            onKeyUp={formik.handleBlur}
            invalid={formik.touched.email && formik.errors.email ? true : false}
            inputProps={{
              sx: {
                width: "100%",
                fontSize: { xs: "17px", xl: "18px" },
                "&::placeholder": {
                  color: "#000",
                  opacity: "0.60",
                },
              },
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
                bgcolor: "#FFFFFF",
                boxShadow: `0px 4px 4px 0px #00000040`,
              },
            }}
            sx={{
              mt: "5px",
              borderRadius: "10px",
              bgcolor: "#FFFFFF",
              "& fieldset": { border: "none" },
              width: "100%",
              input: {
                height: "1.5rem",
              },
            }}
          />
          {formik.touched.email && formik.errors.email ? (
            <Typography
              sx={{ color: "red", textAlign: "left", fontSize: "14px" }}
            >
              {formik.errors.email}
            </Typography>
          ) : null}
          <Typography sx={{ mt: "10px" }}>Password</Typography>
          <Box sx={{ width: "100%", mt: "5px" }}>
            <TextField
              autoComplete="false"
              name="password"
              onChange={(e) => {
                const { value } = e.target;
                if (value.trimStart() !== value) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
              value={formik.values.password}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  formik.handleSubmit();
                }
              }}
              invalid={
                formik.touched.password && formik.errors.password ? true : false
              }
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  backgroundColor: "#FFF",
                  boxShadow: `0px 4px 4px 0px #00000040`,
                },
                endAdornment: (
                  <IconButton onClick={handleClickshowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
              color="secondary"
              inputProps={{
                sx: {
                  width: "100%",
                  fontSize: { xs: "17px", xl: "18px" },
                  "&::placeholder": {
                    color: "#000",
                    opacity: "0.60",
                  },
                },
              }}
              sx={{
                "& fieldset": { border: "none" },
                width: "100%",
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                input: {
                  height: "1.5rem",
                },
              }}
            />
            {formik.touched.password && formik.errors.password ? (
              <Typography
                sx={{ color: "red", textAlign: "left", fontSize: "14px" }}
              >
                {formik.errors.password}
              </Typography>
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            justifyContent: "flex-end",
            display: "flex",
            width: "100%",
          }}
        >
          <Link
            style={{ textDecoration: "none", color: "#000000" }}
            to={"/forgot"}
          >
            <Typography
              sx={{
                color: "#000000",
                fontFamily: "inter",
                fontSize: { xs: "12px", md: "15px", xl: "16px" },
                fontWeight: "400",
                lineHeight: "22.4x",
                alignItems: "center",
                mt: "20px",
              }}
            >
              Forgot Password ?
            </Typography>
          </Link>
        </Box>
        <CustomButton
          sx={{
            mt: "20px",
            p: 2,
            width: "50%",
            bgcolor: "#1D0523",
            borderRadius: "20px",
          }}
          onClick={formik.handleSubmit}
        >
          {loading ? (
            <CircularProgress
              sx={{
                height: { xs: "20px", md: "30px", xl: "40px" },
                width: { xs: "20px", md: "30px", xl: "40px" },
              }}
              color="inherit"
            />
          ) : (
            "Login"
          )}
        </CustomButton>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Login;
