import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import CustomInput from "../../Components/Input/CustomInput";
import UserTable from "../../Components/Tables/UserTable";
import EventsTable from "../../Components/Tables/EventsTable";
import { GetEventList } from "../../Function/Event/GetEvent";
import { SearchDebouncing } from "../../Function/SearchDebounce/Debouncing";
import { API } from "../../Function/Config";
const Events = () => {
  const [date, setDate] = useState(0);
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(false);
  const apiUrl = API.GetEvents;
  const [searchValue, setSearchValue] = useState("");
  const handleEvent = async () => {
    setLoading(true);
    try {
      let res = await GetEventList(date, searchValue);
      setLoading(false);
      setEventData(res.r);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChange = (event) => {
    setDate(event.target.value);
  };

  const handleSearch = async (e) => {
    const v = e.target.value.trim();
    setSearchValue(v);
    try {
      await SearchDebouncing(v, setEventData, apiUrl, date);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleEvent();
  }, [date, searchValue]);

  return (
    <Box>
      <Navbar title={"Events List"} />
      <Box sx={{ p: 1, display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: "30%" }}>
          <CustomInput
            onChange={handleSearch}
            showSearchIcon
            placeholder="Search"
            sx={{
              bgcolor: "#E9E6E8",
              width: "100%",
              borderRadius: "10px",
              border: "none",
              "& fieldset": {
                border: "none !important",
              },
            }}
          />
        </Box>
        <Box sx={{ width: "20%" }}>
          <FormControl fullWidth>
            <Select
              sx={{
                "& fieldset": { border: "none" },
                borderRadius: "5px",
                bgcolor: "#E9E6E8",
                textAlign: "left",
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={date}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem disabled value={0}>
                Filter by Day
              </MenuItem>
              <MenuItem value={1}>Today</MenuItem>
              <MenuItem value={2}>Yesterday</MenuItem>
              <MenuItem value={3}>This Week</MenuItem>
              <MenuItem value={4}>This Month</MenuItem>
              <MenuItem value={5}>This Year</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box m={2}>
        <EventsTable loading={loading} data={eventData} />
      </Box>
    </Box>
  );
};
export default Events;
