import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { Typography, Box, Tooltip } from "@mui/material";
import photo from "../../utils/image/photo.png";
import Map from "./Map";

import { useParams } from "react-router-dom";
import CancelEvent from "../../Components/Modal/CancelEvent";
import { handleCancelEvent } from "../../Function/Event/CancelEvent";
import { GetEventDetails } from "../../Function/Event/GetEventDetails";
import CustomButton from "../../Components/Button/CustomButton/CustomButton";

const EventDetails = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [reason, setReason] = useState("");
  const handleModalOpen = () => {
    setIsOpen(true);
  };
  const maxChars = 80;
  const locationText = data?.location || "";
  const truncatedText =
    locationText.length > maxChars
      ? `${locationText.slice(0, maxChars)}...`
      : locationText;

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleEventDetails = async () => {
    try {
      let res = await GetEventDetails(id);
      setData(res.r);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleEventDetails();
  }, []);

  const handleSubmit = async () => {
    console.log("reason", reason, id);
    try {
      let res = await handleCancelEvent(id, reason);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ height: "90vh" }}>
      <Box>
        <Navbar title={"Events Details"} details={"events"} />
      </Box>
      <Box sx={{ height: "100%", p: 1, boxSizing: "border-box" }}>
        <Box sx={{ height: "70%", display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "20%" }}>
            <Box
              sx={{
                width: "100%",
                borderRadius: "10px 10px 10px 0px",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px 10px 0px 0px",
                }}
                src={
                  data?.event_gallery
                    ? `https://api.proxx.in/uploads/user/images/${data?.event_gallery[0].img}`
                    : ""
                }
                alt="preview"
              />
            </Box>
            <Box
              textAlign={"left"}
              sx={{ border: "1px solid", paddingX: "5px", mt: "-5px" }}
            >
              <Typography sx={{ fontSize: "14px", color: "#000000CC" }}>
                {" "}
                Event Name
              </Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {" "}
                {data?.event_name}
              </Typography>
            </Box>
            <Box
              textAlign={"left"}
              sx={{
                border: "1px solid",
                paddingX: "5px",
              }}
            >
              <Typography sx={{ fontSize: "14px", color: "#000000CC" }}>
                Location
              </Typography>
              <Tooltip title={locationText} arrow>
                <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  {truncatedText}
                </Typography>
              </Tooltip>
            </Box>
            <Box
              textAlign={"left"}
              sx={{ border: "1px solid", paddingX: "5px" }}
            >
              <Typography sx={{ fontSize: "14px", color: "#000000CC" }}>
                Event Start Date
              </Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {new Date(data?.start_time)
                  .toLocaleString("en-US", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })
                  .replace(/\//g, "-") + " "}
              </Typography>
            </Box>
            <Box
              textAlign={"left"}
              pX={"5px"}
              sx={{
                border: "1px solid",
                paddingX: "5px",
                borderRadius: "0px 0px 10px 10px",
              }}
            >
              <Typography sx={{ fontSize: "14px", color: "#000000CC" }}>
                Event End Time
              </Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {" "}
                {new Date(data?.end_time)
                  .toLocaleString("en-US", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })
                  .replace(/\//g, "-") + " "}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: "75%", height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                height: "20%",
              }}
            >
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "1px solid",
                  width: "23%",
                  textAlign: "left",
                  p: "5px",
                }}
              >
                <Typography>Total Seats</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {data?.capacity || "No Seat Limit"}
                </Typography>
              </Box>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "1px solid",
                  width: "23%",
                  textAlign: "left",
                  p: "5px",
                }}
              >
                <Typography>Organizer Name</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {data?.b_name || data?.user_name}
                </Typography>
              </Box>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "1px solid",
                  width: "23%",
                  textAlign: "left",
                  p: "5px",
                }}
              >
                <Typography>Event Sponsors</Typography>

                <Typography sx={{ fontWeight: 600 }}>
                  {data && data?.event_sponsors?.length
                    ? data?.event_sponsors?.map((e) => e.name + ",")
                    : "No Sponsors"}
                </Typography>
              </Box>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "1px solid",
                  width: "23%",
                  textAlign: "left",
                  p: "5px",
                }}
              >
                <Typography>Event Service</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {(data && data?.event_services?.length) || "No Service Added"}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                border: "solid red",
                border: "1px solid",
                height: "70%",
                width: "95%",
                m: "auto",
                mt: "10px",
                borderRadius: "10px",
                p: "5px",
              }}
            >
              <Map lat={data?.lat} lng={data?.lang} />
            </Box>
          </Box>
        </Box>
        {data?.event_services?.length ? (
          <Box sx={{ height: "27%" }}>
            <Box
              sx={{
                mt: 1,
                bgcolor: "#735B79",
                width: "100%",
                display: "flex",
                color: "#fff",
                justifyContent: "space-evenly",
                p: "5px",
                borderRadius: "10px",
              }}
            >
              <Typography>Service Name</Typography>
              <Typography>Date</Typography>
              <Typography>Service Provider</Typography>
              <Typography>Total Price</Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                color: "#000",
                justifyContent: "space-evenly",
                p: "5px",
                borderRadius: "10px",
                border: "1px solid",
                mt: "5px",
              }}
            >
              <Typography>Service Name</Typography>
              <Typography>Date</Typography>
              <Typography>Service Provider</Typography>
              <Typography>Total Price</Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                color: "#000",
                justifyContent: "space-evenly",
                p: "5px",
                borderRadius: "10px",
                border: "1px solid",
                mt: "5px",
              }}
            >
              <Typography>Service Name</Typography>
              <Typography>Date</Typography>
              <Typography>Service Provider</Typography>
              <Typography>Total Price</Typography>
            </Box>
          </Box>
        ) : (
          ""
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            gap: "10px",
          }}
        >
          <CustomButton
            onClick={handleModalOpen}
            sx={{
              color: "#fff",
              cursor: "pointer",
              width: "30%",
              bgcolor: "#735B79",
            }}
          >
            Cancel Event
          </CustomButton>
        </Box>
        <CancelEvent
          open={isOpen}
          onClose={handleModalClose}
          setReason={setReason}
          handleSubmit={handleSubmit}
        />
      </Box>
    </Box>
  );
};

export default EventDetails;
