import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CustomButton from "../Button/CustomButton/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
export default function FaqTable({ data, handleDeleteModal, loading }) {
  const navigate = useNavigate();

  const columns = [
    {
      field: "question",
      headerName: "question",
      headerAlign: "center",
      align: "center",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "answer",
      headerName: "answer",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "status",
      headerName: "status",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      editable: true,
      renderCell: (params) => (
        <Box spacing={2} direction="row" sx={{ display: "flex" }}>
          <Box sx={{ mr: 2 }}>
            <CustomButton
              onClick={() => {
                navigate(`/addfaq/${params.id}`, {
                  state: {
                    question: params.row.question,
                    answer: params.row.answer,
                  },
                });
              }}
              sx={{ bgcolor: "#735B79" }}
            >
              Edit
            </CustomButton>
          </Box>
          <Box>
            <CustomButton
              onClick={() => handleDeleteModal(params.row.id)}
              sx={{ bgcolor: "#8B5455" }}
            >
              Delete
            </CustomButton>
          </Box>
        </Box>
      ),
    },
  ];
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      {loading ? (
        <Box sx={{ mt: "20%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <DataGrid
          editMode="false"
          disableSelectionOnClick
          disableRowSelectionOnClick
          rows={data ? data : []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          disableColumnMenu
          pageSizeOptions={[5]}
          sx={{ border: "0px" }}
        />
      )}
    </Box>
  );
}
