import {
  Box,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import CustomInput from "../../Components/Input/CustomInput";
import UserTable from "../../Components/Tables/UserTable";
import { GetUser } from "../../Function/User/GetUser.js";
import { SearchDebouncing } from "../../Function/SearchDebounce/Debouncing2.js";
import { API } from "../../Function/Config.js";
const User = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [count, setCount] = useState(0);
  const [total_count, setTotal_count] = useState(0);
  const [type, setType] = useState(1);
  const apiUrl = API.getuser;

  const handleNext = () => {
    setCount((prev) => prev + 10);
  };

  const handleBack = () => {
    setCount((prev) => prev - 10);
  };

  const handleUser = async () => {
    try {
      setIsLoading(true);
      let res = await GetUser(type, count);
      setData(res.r.data);
      setTotal_count(res.r.t_count);
      setLoading(false);
      setIsLoading(false);
    } catch (error) {
      setLoading(false);
      setIsLoading(false);
      console.log(error);
    }
  };
  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleSearch = async (e) => {
    console.log("sdasdsd")
    setSearchValue(e.target.value.trim());
    try {
      await SearchDebouncing(searchValue, setData, apiUrl, type);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleUser();
  }, [type, count]);

  
  return (
    <Box>
      <Navbar title={"User List"} />
      <Box sx={{ p: 1 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CustomInput
            onChange={handleSearch}
            showSearchIcon
            placeholder="Search"
            sx={{
              bgcolor: "#E9E6E8",
              width: "30%",
              borderRadius: "10px",
              border: "none",
              "& fieldset": {
                border: "none !important",
              },
            }}
          />
          <Box
            sx={{
              width: "30%",
              display: "flex",
              p: 1,
              boxSizing: "border-box",
              bgcolor: "#E9E6E8",
              borderRadius: "10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              onClick={() => setType(1)}
              sx={{
                bgcolor: type === 1 ? "#fff" : "",
                width: "45%",
                cursor: "pointer",
                p: 1,
                borderRadius: "10px",
              }}
            >
              <Typography>User</Typography>
            </Box>
            <Box
              onClick={() => setType(2)}
              sx={{
                bgcolor: type === 2 ? "#fff" : "",
                width: "45%",
                cursor: "pointer",
                p: 1,
                borderRadius: "10px",
              }}
            >
              <Typography>Business</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box m={1}>
        {loading ? (
          <Box sx={{ mt: "20%" }}>
            <CircularProgress />
          </Box>
        ) : (
          <UserTable
            handleNext={handleNext}
            handleBack={handleBack}
            data={data}
            total_count={total_count}
            count={count}
            isLoading={isLoading}
            handleUser={handleUser}
          />
        )}
      </Box>
    </Box>
  );
};

export default User;
