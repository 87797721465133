import { Alert, Box, IconButton, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { useFormik } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import upload from "../../utils/image/upload.png";
import * as Yup from "yup";
import { handleAddBlog } from "../../Function/Blog/AddBlog";
import { GetBlog } from "../../Function/Blog/GetBlog";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomInput from "../../Components/Input/CustomInput";
import CustomButton from "../../Components/Button/CustomButton/CustomButton";

const AddBlog = () => {
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const handleRemoveImage = () => {
    setImagePreview(null);
    formik.setFieldValue("image", null);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      formik.setFieldValue("image", selectedFile);
      const imageURL = URL.createObjectURL(selectedFile);
      setImagePreview(imageURL);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      image: null,
      title: "",
      description: "",
      id: null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title Is Required"),
      description: Yup.string().required("Description Is Required"),
      image: Yup.string().required("Please Upload Image"),
    }),

    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let res = await handleAddBlog(values);
        if (res.s) {
          setSnackbarMessage("Interest Added successfully");
          setSnackbarOpen(true);
          setImagePreview(null);
          navigate("/blog");
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        resetForm();
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });
  console.log(formik.values);

  useEffect(() => {
    if (id) {
      formik.setValues({
        title: location.state.title,
        image: location.state.img,
        id: id,
        description: location.state.description,
      });
      setImagePreview(
        `https://api.proxx.in/uploads/blogs/${location.state.img}`
      );
    }
  }, []);

  return (
    <Box>
      <Navbar title={id ? "Edit Blog" : "Add Blog"} />{" "}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success">{snackbarMessage}</Alert>
      </Snackbar>
      <Box p={2}>
        {imagePreview ? (
          <Box
            sx={{
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: "3px",
              mt: "5px",
              background: `linear-gradient(165deg,#1B4DC0E5, #eee)`,
              mt: "10px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#fff",
                width: "100%",
                borderRadius: "15px",
                p: 2,
                boxSizing: "border-box",
              }}
            >
              <Box
                sx={{
                  width: "130px",
                  height: "130px",
                  m: "auto",
                  position: "relative",
                }}
              >
                <img
                  width={"100%"}
                  height={"100%"}
                  src={imagePreview}
                  alt="preview"
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: "white",
                  }}
                  onClick={handleRemoveImage}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: "3px",
              mt: "5px",
              background: `linear-gradient(165deg,#1B4DC0E5, #eee)`,
              mt: "10px",
            }}
          >
            <label
              style={{
                display: "block",
                width: "100%",
                borderRadius: "10px",
                height: "150px",
                cursor: "pointer",
                textAlign: "center",
                lineHeight: "150px",
                display: imagePreview ? "flex" : "block",
                backgroundColor: "#fff",
              }}
            >
              <Box>
                <Box
                  sx={{
                    width: "50px",
                    height: "50px",
                    m: "auto",
                  }}
                >
                  <img
                    width={"100%"}
                    height={"100%"}
                    src={upload}
                    alt="preview"
                  />
                </Box>
                <Typography sx={{ mt: "40px" }}>Upload a Photo</Typography>
              </Box>

              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </label>
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
            textAlign: "left",
            borderRadius: "10px",
            boxSizing: "border-box",
            p: "2px",
            background: `linear-gradient(165deg,#1B4DC0E5, #eee)`,
            mt: "15px",
          }}
        >
          <CustomInput
            name="title"
            onChange={formik.handleChange}
            value={formik.values.title}
            sx={{
              width: "100%",
              background: "#fff",
              border: "none",
              borderRadius: "10px",
              "& fieldset": { border: "none !important" },
            }}
            placeholder="Blog Title"
          />{" "}
          {formik.touched.title && formik.errors.title ? (
            <Typography
              sx={{ color: "red", textAlign: "left", fontSize: "14px" }}
            >
              {formik.errors.title}
            </Typography>
          ) : null}{" "}
        </Box>
        <Box
          sx={{
            width: "100%",
            textAlign: "left",
            borderRadius: "10px",
            boxSizing: "border-box",
            p: "2px",
            background: `linear-gradient(165deg,#1B4DC0E5, #eee)`,
            mt: "15px",
          }}
        >
          <CustomInput
            name="description"
            onChange={formik.handleChange}
            value={formik.values.description}
            sx={{
              width: "100%",
              background: "#fff",
              border: "none",
              borderRadius: "10px",
              "& fieldset": { border: "none !important" },
            }}
            placeholder="Blog Description"
          />
        </Box>
        {formik.touched.description && formik.errors.description ? (
          <Typography
            sx={{ color: "red", textAlign: "left", fontSize: "14px" }}
          >
            {formik.errors.description}
          </Typography>
        ) : null}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end", p: 2, gap: "10px" }}>
        <CustomButton
          sx={{
            width: "15%",
            color: "#000",
            bgcolor: "#fff",
            "&:hover": { color: "#000", bgcolor: "#fff" },
          }}
        >
          Cancel
        </CustomButton>
        <CustomButton
          sx={{
            width: "15%",
            "&:disabled": {
              color: "#fff",
              bgcolor: "gray",
            },
          }}
          disabled={loading || !(formik.dirty && formik.isValid)}
          onClick={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          {id ? "Edit" : "Add"}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default AddBlog;
