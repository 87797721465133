import { API, API_POST } from "../Config";

export const handleAddInterest = (payload) => {
  const f = new FormData();

  f.append("thumb", payload.thumb);
  f.append("intrest", payload.interest);

  if (payload.id) {
    f.append("id", payload.id);
    return API_POST(API.editinterest, f);
  } else {
    return API_POST(API.addinterest, f);
  }
};
