import axios from "axios";

export const API = {
  baseURL: "https://api.proxx.in/",

  // Auth
  login: "api/admin-login",

  //FAQ
  getfaq: "/api/auth/admin/faq/get-list",
  addfaq: "/api/auth/admin/faq/create",
  editfaq: "/api/auth/admin/faq/edit",

  // Event
  GetEvents: "/api/auth/admin/event-list",
  cancelevent: "/api/auth/event/edit",
  geteventdetails: "/api/auth/event/get-id",

  // Services
  getService: "/api/auth/service/get-admin",
  
  // Payment
  getPayment: "/api/auth/admin/get-payment",

  // Boost
  getBoost: "/api/auth/admin/get-boost",

  // Interest
  addinterest: "/api/auth/admin/interest/create",
  getinterest: "/api/auth/admin/interest/get-list",
  editinterest: "/api/auth/admin/interest/edit",

  // Privacy Policy
  updatepolicy: "/api/auth/admin/legal/privay-policy/add",
  getpolicy: "/api/auth/admin/legal/privay-policy/get",

  // Terms
  updateterms: "/api/auth/admin/legal/t-c/add",
  getterms: "/api/auth/admin/legal/t-c/get",

  // Blog
  getblog: "/api/auth/admin/blog/get-list",
  addblog: "/api/auth/admin/blog/create",
  editblog: "/api/auth/admin/blog/edit",

  // User
  getuser: "/api/auth/users/get-list",
  userdetails: "/api/auth/users/get-details",
  blockuser: "/api/auth/admin/user-operation",

  // Advertise
  getadvertise: "/api/auth/story/get-admin",
  addadvertise: "/api/auth/story/upload",
  editadvertise: "/api/auth/story/del-admin",

  // Links
  addlinks: "/api/landing/social-media-upd",
  getlinks: "/api/landing/social-media-get",

  // Dashboard Analytics
  getdashboardAnayltics: "/api/auth/admin/dashboard-anayltics",
};

const Axios = axios.create({
  baseURL: API.baseURL,
});

export const API_GET_ID = async (url, id) => {
  const storedData = localStorage.getItem("ProXAdmin");

  let { token } = JSON.parse(storedData);
  try {
    const { data } = await Axios.get(url, {
      params: {
        id: id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};
export const API_GET = async (url) => {
  const storedData = localStorage.getItem("ProXAdmin");

  try {
    let { token } = JSON.parse(storedData);

    const { data } = await Axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};
export const API_GET_P = async (url, count, usertype) => {
  console.log("u", usertype);
  try {
    const token = localStorage.getItem("token");
    const apikey = localStorage.getItem("apikey");

    // Initialize the URI with the base URL and common parameters
    let uri = `${url}?count=${count}&token=${token}&apikey=${apikey}`;
    if (usertype) {
      uri = `${url}?type=${usertype}&token=${token}&apikey=${apikey}`;
    }

    const { data } = await Axios.get(uri, {
      headers: null,
    });

    return data;
  } catch (error) {
    return error;
  }
};
export const API_GET_ORDER = async (url, usertype) => {
  console.log("u", usertype);
  try {
    const token = localStorage.getItem("token");
    const apikey = localStorage.getItem("apikey");

    // Initialize the URI with the base URL and common parameters
    let uri = `${url}&token=${token}&apikey=${apikey}`;
    if (usertype) {
      uri = `${url}?type=${usertype}&token=${token}&apikey=${apikey}`;
    }
    const { data } = await Axios.get(uri, {
      headers: null,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const API_POST = async (url, data) => {
  let token;
  const storedData = localStorage.getItem("ProXAdmin");
  if (storedData) {
    const parsedData = JSON.parse(storedData);
    token = parsedData.token;
  }
  try {
    const res = await Axios.post(url, data, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const API_GET_SEARCH = async (type, searchterm, recipetype) => {
  try {
    const token = localStorage.getItem("token");
    const apikey = localStorage.getItem("apikey");
    if (recipetype) {
      const url = `/api/admin/search?type=${type}&search=${searchterm}&token=${token}&apikey=${apikey}&recipe_type=${recipetype}`;
      const { data } = await Axios.get(url, {});
      return data;
    } else {
      const url = `/api/admin/search?type=${type}&search=${searchterm}&token=${token}&apikey=${apikey}`;
      const { data } = await Axios.get(url, {});
      return data;
    }
  } catch (error) {
    return error;
  }
};
