import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const [userID, setUserID] = useState("");
  useEffect(() => {
    let storedObject = localStorage.getItem("ProXAdmin");
    if (storedObject) {
      let parsedObject = JSON.parse(storedObject);
      let u = parsedObject.id;
      if (u) {
        setUserID(u);
      }
    }
  }, []);

  const storedData = localStorage.getItem("ProXAdmin");

  if (storedData) {
    const { token } = JSON.parse(storedData);

    if (token) {
      return children;
    }
  }
  return <Navigate to="/login" />;
};

export default PrivateRoute;
