import { API, API_POST } from "../Config";

export const handleAddFaq = (payload, id) => {
  const f = new FormData();
  f.append("question", payload.question);
  f.append("answer", payload.answer);

  if (id) {
    f.append("id", id);
    return API_POST(API.editfaq, f);
  } else {
    return API_POST(API.addfaq, f);
  }
};
