import { InputAdornment, TextField, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const CustomInputField = styled(TextField)(({ backgroundColor }) => ({
  "& .MuiOutlinedInput-root": {
    color: "#fff",
    "& fieldset": {
      border: "2px solid #787878E5",
      borderRadius: "10px",
      color: "#000",
    },
    "&:hover fieldset": {
      borderColor: "#787878E5",
      color: "#000",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#787878E5",
    },
    borderRadius: "10px",
  },
  "& .MuiInputBase-input": {
    color: "#000",
    "&::placeholder": {
      color: { xs: "#FFFF", md: "#cbcccb" },
      opacity: "0.4",
      fontSize: "16px",
      lineHeight: "10px",
      "@media (max-width: 600px)": {
        fontSize: "14px",
      },
      fontWeight: "400",
      fontFamily: "inter",
    },
  },
}));

const CustomInput = ({ showSearchIcon = false, ...props }) => {
  return (
    <CustomInputField
      {...props}
      autoComplete="off"
      
      InputProps={{
        startAdornment: showSearchIcon && (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
export default CustomInput;
