// DashboardRoutes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import Dashboard from "../../Pages/Dashboard.jsx/Dashboard";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Interest from "../../Pages/Interest/Interest";
import Faq from "../../Pages/FAQ/Faq";
import AddFaq from "../../Pages/FAQ/AddFaq";
import Legal from "../../Pages/Legal/Legal";
import User from "../../Pages/User/User";
import Events from "../../Pages/Events/Events";
import Blog from "../../Pages/Blog/Blog";
import AddBlog from "../../Pages/Blog/AddBlog";
import EventDetails from "../../Pages/Events/EventDetails";
import Advertise from "../../Pages/Advertise/Advertise";
import UserDetails from "../../Pages/User/UserDetails";
import EventsCreated from "../../Pages/User/EventsCreated";
import EventsJoined from "../../Pages/User/EventsJoined";
import ServiceCreated from "../../Pages/User/ServiceCreated";
import EventExperience from "../../Pages/User/EventExperience";
import Links from "../../Pages/Links/Links";
import Services from "../../Pages/Services/Services";
import Payment from "../../Pages/Payment/Payment";
import Boost from "../../Pages/Boost/Boost";

const DashboardRoutes = () => {
  return (
    <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
      <Box>
        <Sidebar />
      </Box>

      <Box sx={{ width: "100%" }}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/interest" element={<Interest />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/addfaq" element={<AddFaq />} />
          <Route path="/addfaq/:id" element={<AddFaq />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/user" element={<User />} />
          <Route path="/events" element={<Events />} />
          <Route path="/event/:id" element={<EventDetails />} />
          <Route path="/services" element={<Services />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/boost" element={<Boost />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/addblog/:id" element={<AddBlog />} />
          <Route path="/addblog" element={<AddBlog />} />
          <Route path="/advertise" element={<Advertise />} />
          <Route path="/user/:id" element={<UserDetails />} />
          <Route path="/eventscreated" element={<EventsCreated />} />
          <Route path="/eventsjoined" element={<EventsJoined />} />
          <Route path="/servicecreated" element={<ServiceCreated />} />
          <Route path="/eventexperience" element={<EventExperience />} />
          <Route path="/links" element={<Links />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default DashboardRoutes;
