import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CustomButton from "../Button/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useState } from "react";

export default function ServiceTable({ data, loading }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [id, setId] = useState();

  const columns = [
    {
      field: "photo",
      headerName: "Photos",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      renderCell: (params) => (
        <Box
          sx={{ width: "100px", height: "50px", borderRadius: "10px", p: 1 }}
        >
          <img
            style={{ borderRadius: "10px" }}
            width={"100%"}
            height={"100%"}
            src={`https://api.proxx.in/uploads/user/images/${params.row.img}`}
            alt="preview"
          />
        </Box>
      ),
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "Service Name",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      flex: 1,
      editable: true,
      renderCell: (params) => {
        const createdAt = new Date(params.row.created_at);
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(createdAt);
        return <Typography>{formattedDate}</Typography>;
      },
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "price",
      headerName: "Total Price",
      headerAlign: "center",
      align: "center",
      flex: 1,
      editable: true,
    },
  ];
  return (
    <Box
      sx={{
        height: "calc(100vh - 230px)",
        width: "100%",
        overflow: "auto",
      }}
    >
      {loading ? (
        <Box sx={{ mt: "20%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <DataGrid
          editMode="false"
          disableSelectionOnClick
          disableRowSelectionOnClick
          rows={data ? data : []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          disableColumnMenu
          pageSizeOptions={[10]}
          sx={{ border: "0px" }}
        />
      )}
    </Box>
  );
}
