import { API_GET } from "../../Config";

let debounceTimeout;

const SearchDebouncing2 = (url, setData) => {
  clearTimeout(debounceTimeout);
  debounceTimeout = setTimeout(async () => {
    try {
      let res = await API_GET(url);
      setData(res.r);
    } catch (error) {
      console.log(error);
    }
  }, 200);
};
export { SearchDebouncing2 };
