import React, { useState } from "react";

import { Box, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../Button/CustomButton/CustomButton";
import CustomInput from "../Input/CustomInput";

const CancelEvent = ({
  open,
  modalID,
  onClose,
  id,
  setReason,
  reason,
  handleSubmit,
}) => {
  return (
    <>
      <Modal open={open} modalID={modalID} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            outline: "none",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            width: { xs: "80%", sm: 500 },
            p: { xs: 2, md: 5 },
            borderRadius: "20px",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              {" "}
              <Typography
                sx={{
                  fontSize: { xs: "17px", sm: "20px" },
                  textAlign: "left",
                  lineHeight: "22px",
                  mt: 1,
                }}
              >
                Are You Sure Want To Cancel Event ?
              </Typography>
            </Box>
            <CloseIcon onClick={onClose} />
          </Box>
          <Box>
            <CustomInput
              placeholder={"Reason for Cancelation"}
              sx={{
                width: "100%",
                mt: "10px",
                "& .MuiInputBase-input": { borderRadius: "0px", p: 0.3 },
              }}
              multiline
              rows={4}
              onChange={(e) => setReason(e.target.value)}
            />
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "left",
                lineHeight: "22px",
              }}
            >
              By canceling event, payment will be refund to the participants.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "15px", mt: 1 }}>
            <CustomButton
              onClick={onClose}
              sx={{
                bgcolor: "#000",
                "&:hover": {
                  bgcolor: "#000",
                },
              }}
            >
              No
            </CustomButton>
            <CustomButton
              disabled={reason?.length == 0}
              onClick={handleSubmit}
              sx={{
                bgcolor: "red",
                "&:hover": {
                  bgcolor: "red",
                },
              }}
            >
              Yes
            </CustomButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CancelEvent;
