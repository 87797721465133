import { API, API_POST } from "../Config";

export const handleEditAdvertise = (id) => {
  const f = new FormData();

  f.append("id", id);
  f.append("status", 0);

  return API_POST(API.editadvertise, f);
};
