import React, { useState } from "react";

import { Box, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../Button/CustomButton/CustomButton";

const ConfirmModal = ({ open, modalID, onClose, handleDelete, id, type }) => {
  return (
    <>
      <Modal open={open} modalID={modalID} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            outline: "none",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            width: { xs: "80%", sm: 500 },
            p: { xs: 2, md: 5 },
            borderRadius: "20px",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              {" "}
              <Typography
                sx={{
                  fontSize: { xs: "17px", sm: "20px" },
                  textAlign: "left",
                  lineHeight: "22px",
                  mt: 1,
                }}
              >
                {`Are You Sure You Want To ${
                  type == "-1" ? "Block" : "Delete"
                }?`}
              </Typography>
            </Box>
            <CloseIcon onClick={onClose} />
          </Box>

          <Box sx={{ display: "flex", gap: "15px", mt: 5 }}>
            <CustomButton
              onClick={onClose}
              sx={{
                bgcolor: "#000",
                "&:hover": {
                  bgcolor: "#000",
                },
              }}
            >
              No
            </CustomButton>
            <CustomButton
              onClick={() => handleDelete(id)}
              sx={{
                bgcolor: "red",
                "&:hover": {
                  bgcolor: "red",
                },
              }}
            >
              Yes
            </CustomButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmModal;
