import { API, API_GET } from "../Config";

let debounceTimeout;

const SearchDebouncing = async (searchterm, setServiceData, apiUrl) => {
  try {
    let res = await API_GET(`${apiUrl}?search=${searchterm}`);
    console.log(res.r);
    setServiceData(res.r);
  } catch (error) {
    console.log(error);
  }
};

export { SearchDebouncing };
