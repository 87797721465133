import {
  Alert,
  Box,
  InputAdornment,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import CustomInput from "../../Components/Input/CustomInput";
import { Link } from "react-router-dom";
import { SearchDebouncing2 } from "../../Function/FAQ/Search/Debouncing2";
import ConfirmModal from "../../Components/Modal/ConfirmModal";
import BlogTable from "../../Components/Tables/BlogTable";
import { GetBlog } from "../../Function/Blog/GetBlog";
import { handleDeleteBlog } from "../../Function/Blog/DeleteBlog";

const Blog = () => {
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");

  const handleDelete = async (id) => {
    try {
      let res = handleDeleteBlog(id);
      if (res.s) {
        setSnackbarOpen(true);
        setSnackbarMessage("Blog Deleted successfully");
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      onClose();
      handleBlog();
    } catch (error) {
      console.log(error);
    }
  };
  const onClose = () => {
    setIsModalOpen(false);
  };
  const handleDeleteModal = (i) => {
    setIsModalOpen(true);
    setId(i);
  };
  const handleBlog = async () => {
    setLoading(true);
    try {
      let res = await GetBlog();
      setData(res.r);
      setLoading(false);
      setInitialData(res.r);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleBlog();
  }, []);

  console.log(data);

  const handleSearch = async (e) => {
    const searchValue = e.target.value.trim();
    try {
      if (searchValue === "") {
        setData(initialData);
      } else {
        let url = `https://api.proxx.in/api/auth/admin/blog/search?search=${searchValue}`;
        await SearchDebouncing2(url, setData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  return (
    <Box>
      <Navbar title={"Blog"} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success">{snackbarMessage}</Alert>
      </Snackbar>
      <ConfirmModal
        id={id}
        setIsModalOpen={setIsModalOpen}
        handleDelete={handleDelete}
        open={isModalOpen}
        onClose={onClose}
      />
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "5px",
            p: 2,
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "30%" }}>
            <CustomInput
              onChange={handleSearch}
              showSearchIcon
              placeholder="Search Blog"
              sx={{
                bgcolor: "#E9E6E8",
                width: "100%",
                borderRadius: "10px",
                border: "none",
                "& fieldset": {
                  border: "none !important",
                },
              }}
            />
          </Box>
          <Box>
            <Link to={"/addblog"} style={{ textDecoration: "none" }}>
              <Typography sx={{ color: "#00000099", fontSize: "18px" }}>
                + Add Blog
              </Typography>
            </Link>
          </Box>
        </Box>
        <Box sx={{ m: 2 }}>
          <BlogTable
            handleDeleteModal={handleDeleteModal}
            data={data}
            loading={loading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Blog;
