import { API, API_GET } from "../Config";

let debounceTimeout;

const SearchDebouncing = (searchterm, setEventData, apiUrl, date) => {
  clearTimeout(debounceTimeout);
  debounceTimeout = setTimeout(async () => {
    try {
      let res = await API_GET(`${apiUrl}?search=${searchterm}&role=${date}`);
      setEventData(res.r.data);
    } catch (error) {
      console.log(error);
    }
  }, 200);
};

export { SearchDebouncing };
