import { API, API_POST } from "../Config";

export const handleAddBlog = (payload) => {
  const f = new FormData();

  f.append("img", payload.image);
  f.append("title", payload.title);
  f.append("description", payload.description);

  if (payload.id) {
    f.append("id", payload.id);
    return API_POST(API.editblog, f);
  } else {
    return API_POST(API.addblog, f);
  }
};
