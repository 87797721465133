import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CustomButton from "../Button/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import CancelEvent from "../Modal/CancelEvent";
import { handleCancelEvent } from "../../Function/Event/CancelEvent";
import { useState } from "react";

export default function EventsTable({ data, loading }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [id, setId] = useState();
  const handleModalClose = () => {
    setIsOpen(false);
  };
  const handleSubmit = async () => {
    console.log("reason", reason, id);
    try {
      let res = await handleCancelEvent(id, reason);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteModal = (e) => {
    setId(e);
    setIsOpen(true);
  };

  const columns = [
    {
      field: "photo",
      headerName: "Photos",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      renderCell: (params) => (
        <Box
          sx={{ width: "100px", height: "50px", borderRadius: "10px", p: 1 }}
        >
          <img
            style={{ borderRadius: "10px" }}
            width={"100%"}
            height={"100%"}
            src={`https://api.proxx.in/uploads/user/images/${params.row.event_img}`}
            alt="preview"
          />
        </Box>
      ),
      disableColumnMenu: true,
    },
    {
      field: "event_name",
      headerName: "Events Name",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      flex: 1,
      editable: true,
      renderCell: (params) => {
        const createdAt = new Date(params.row.created_at);
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(createdAt);
        return <Typography>{formattedDate}</Typography>;
      },
    },

    {
      field: "type",
      headerName: "Event Type",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 0.5,
      renderCell: (params) => (
        <Typography>{params.row.type === 1 ? "Free" : "Paid"}</Typography>
      ),
    },
    {
      field: "organizer_name",
      headerName: "Organizer Name",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "status",
      headerName: "status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <Box spacing={2} direction="row" sx={{ display: "flex" }}>
          <Box sx={{ mr: 2 }}>
            <CustomButton
              onClick={() => {
                navigate(`/event/${params.id}`);
              }}
              sx={{ bgcolor: "#735B79", "&:hover": { bgcolor: "#735B79" } }}
            >
              View
            </CustomButton>
          </Box>
          <Box>
            <CustomButton
              onClick={() => handleDeleteModal(params.row.id)}
              sx={{
                bgcolor: "red",
                "&:hover": {
                  background: "red",
                },
              }}
            >
              Cancel
            </CustomButton>
          </Box>
        </Box>
      ),
    },
  ];
  return (
    <Box
      sx={{
        maxHeight: "calc(100vh - 230px)",
        width: "100%",
        overflow: "auto",
      }}
    >
      <CancelEvent
        open={isOpen}
        onClose={handleModalClose}
        setReason={setReason}
        handleSubmit={handleSubmit}
        reason={reason}
      />
      {loading ? (
        <Box sx={{ mt: "20%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <DataGrid
          editMode="false"
          disableSelectionOnClick
          disableRowSelectionOnClick
          rows={data ? data : []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          disableColumnMenu
          pageSizeOptions={[10]}
          sx={{ border: "0px" }}
        />
      )}
    </Box>
  );
}
