import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Notification from "./Notification";
import { GetEventList } from "../../Function/Event/GetEvent";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import EventsTable2 from "../../Components/Tables/EventsTable2";
import { useEffect } from "react";
import { getDashboadChart } from "../../Function/Dashboard/getChartData";
import EventsTable from "../../Components/Tables/EventsTable";

// const formatValue = (value) => {
//   if (value > 999) {
//     return `${Math.round(value / 1000)}k`;
//   }
//   return value;
// };

const MetricGauge = ({ label, value }) => {
  return (
    <Box
      sx={{
        bgcolor: "#2E0338",
        padding: "1rem",
        borderRadius: "10px",
        border: "1px solid #fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          color: "#fff",
          fontWeight: "700",
          minHeight: "3rem",
          fontSize: { md: "14px" },
        }}
      >
        {label}
      </Typography>
      <Gauge
        cornerRadius="50%"
        value={value}
        sx={{
          width: { xs: "100px", xl: "150px" },
          height: { xs: "100px", xl: "150px" },
          [`& .${gaugeClasses.valueText}`]: {
            "& text": {
              fontSize: { xs: 20, xl: 20 },
              fill: "#FC58B3",
              fontWeight: "600",
            },
          },
          [`& .${gaugeClasses.valueArc}`]: {
            fill: "#FD749B",
          },
          [`& .${gaugeClasses.referenceArc}`]: {
            fill: "#FAA9C6",
          },
        }}
      />
    </Box>
  );
};

const Dashboard = () => {
  const [chartData, setChartData] = useState();
  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState([]);

  const handleEvent = async () => {
    setLoading(true);
    try {
      let res = await GetEventList(0, "");
      setLoading(false);
      setEventData(res.r.splice(0, 8));
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleData = async () => {
    try {
      let res = await getDashboadChart();
      console.log(res);
      if (res && res.r) {
        const mappedData = [
          { label: "Total Number Of Users", value: res.r.total_users },
          { label: "Total Number Of Hosts", value: res.r.number_of_hosts },
          { label: "Total Number Of Vendors", value: res.r.number_of_vendors },
          { label: "Total Number Of Events", value: res.r.number_of_events },
          { label: "Total Income", value: res.r.total_income },
        ];
        setChartData(mappedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleData();
    handleEvent();
  }, []);

  return (
    <Box>
      <Navbar title={"Dashboard"} />
      <Box sx={{ px: 2 }}>
        {/* {/ ......... /} */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            bgcolor: "#735B79",
            p: 2,
            my: 2,
            borderRadius: "10px",
          }}
        >
          {chartData?.map((metric) => (
            <MetricGauge
              key={metric.label}
              label={metric.label}
              value={metric.value}
            />
          ))}
        </Box>
        {/* {/ ..... /} */}

        <Box
          sx={{
            borderRadius: "10px",
          }}
        >
          <EventsTable loading={loading} data={eventData} />
        </Box>
        {/* <Box
            sx={{
              width: "29%",
              border: "1px solid",
              borderRadius: "10px",
              bgcolor: "#735B79",
              overflow: "auto",
              height: { xs: "calc(100vh - 320px)", md: "calc(100vh - 360px)" },
            }}
          >
            <Notification />
          </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
