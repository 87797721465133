import { Button, styled } from "@mui/material";

const CustomButtons = styled(Button)({
  background: "#161616", // Change the background color
  // padding: "18px 15px 18px 15px",
  padding: "10px",
  borderRadius: "5px",
  fontFamily: "inter",
  fontSize: "16px",
  color: "#F8F8F8",
  textTransform: "capitalize",
  fontWeight: "400",
  wordSpacing: "normal",
  lineHeight: "16px",
  width: "100%",
  cursor: "pointer",
  "&:hover": {
    background: "#000000", // Change the background color on hover
  },
});

const CustomButton = ({ ...props }) => {
  return <CustomButtons {...props} />;
};
export default CustomButton;
