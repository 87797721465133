import { API, API_POST } from "../Config";

export const AddLinks = (payload) => {
  const f = new FormData();
  f.append("fb_link", payload.fb_link);
  f.append("tiktok_link", payload.tiktok_link);
  f.append("insta_link", payload.insta_link);
  f.append("twitter_link", payload.twitter_link);
  return API_POST(API.addlinks, f);
};
