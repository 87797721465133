import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";

import free from "../../utils/image/free.png";
import paid from "../../utils/image/paid.png";
import { Link } from "react-router-dom";
import { CleaningServices } from "@mui/icons-material";
const MyEvents = ({ events, id }) => {
    
  return (
    <Box sx={{ width: "100%", color: "#000" }}>
      {Array.isArray(events) && events.length ? (
        <Grid container spacing={4}>
          {events?.map((e, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Link
                style={{ textDecoration: "none" }}
                to={`/event/${e?.id}`
                  // id == localStorage.getItem("ProX")?.id
                  //   ? `/hostevent/${e?.id}`
                  //   : `/myevent/${e?.id}`
                }
              >
                <Paper
                  sx={{
                    background: "transparent",
                    color: "#000",
                    boxShadow: "none",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      textAlign: "left",
                      background: "transparent",
                      gap: { xs: "2px", md: "10px" },
                    }}
                  >
                    <Box
                      sx={{
                        width: "40%",
                        height: { xs: "125px", md: "150px" },
                      }}
                    >
                      <img
                        width={"100%"}
                        height={"100%"}
                        src={`https://api.proxx.in/uploads/user/images/${e?.event_gallery[0]?.img}`}
                        alt="preview"
                        style={{ borderRadius: "10px" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: "60%",
                        p: { xs: 0.5, md: 1 },
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: { xs: "13px", sm: "15px", md: "18px" },
                        }}
                      >
                        {e?.event_name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Typography
                          noWrap
                          sx={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: { xs: "12px", sm: "14px", md: "18px" },
                          }}
                        >
                          {new Date(
                            e?.start_time || e?.created_at
                          ).toLocaleString("en-US", {
                            month: "short",
                            day: "2-digit",
                          })}{" "}
                        </Typography>
                        <Typography
                          noWrap
                          sx={{
                            color: "#000",
                            fontSize: {
                              xs: "15px",
                              sm: "14px",
                              md: "15px",
                              lg: "16px",
                              xl: "16px",
                            },
                            fontFamily: "Inter",
                            fontWeight: "500px",
                            lineHeight: {
                              xs: "22.5px",
                              md: "22px",
                              xl: "30px",
                            },
                          }}
                          variant="h6"
                        >
                          {e?.location?.length > 15
                            ? `${e?.location.slice(0, 15)}...`
                            : e?.location}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: { xs: "30px", sm: "35px", md: "40px" },
                          height: { xs: "25px", sm: "28px", md: "30px" },
                        }}
                      >
                        <img
                          width={"100%"}
                          src={e?.type === 1 ? free : paid}
                          alt="preview"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box></Box>
                </Paper>
              </Link>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>No Events Created</Typography>
      )}
    </Box>
  );
};

export default MyEvents;
