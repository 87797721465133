import React, { useEffect, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

const Map = ({ lat, lng }) => {
  const [center, setcenter] = useState({
    lat: lat,
    lng: lng,
  });

  const getLog = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setcenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  };

  useEffect(() => {
    getLog();
  }, []);

  return (

    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        m: "auto",
      }}
      center={{ lat: lat, lng: lng }}
      zoom={10}
    >
      <Marker position={{ lat: lat, lng: lng }} />
    </GoogleMap>
    //   </LoadScript>
  );
};

export default Map;