import { API, API_POST } from "../Config";

export const AddAdvertise = (payload) => {
  const f = new FormData();
  f.append("is_admin", 1);
  f.append("media", payload.media);
  f.append("media_type", payload.filetype);
  f.append("bg_color", payload.bg_color || "#1B4DC0");

  return API_POST(API.addadvertise, f);
};
