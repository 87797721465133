import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import bin from "../../utils/image/bin.png";
import eye from "../../utils/image/Hide.png";
import block from "../../utils/image/block.png";
import { Link, useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useState } from "react";
import { DeleteUser } from "../../Function/User/GetUserDelete";
import ConfirmModal from "../Modal/ConfirmModal";

export default function UserTable({
  data,
  total_count,
  handleNext,
  handleBack,
  count,
  isLoading,
  handleUser,
}) {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [type, setType] = useState("");
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");

  const handleConfirm = (id, status) => {
    setOpen(true);
    setType(status);
    setId(id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async (id, type) => {
    try {
      let res = await DeleteUser(id, type);
      setOpen(false);
      handleUser();
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      field: "profile",
      headerName: "Profile",
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box
          sx={{ width: "100px", height: "50px", borderRadius: "10px", p: 1 }}
        >
          <img
            style={{ borderRadius: "10px" }}
            width={"100%"}
            height={"100%"}
            src={
              params.row.profile_img
                ? `https://api.proxx.in/uploads/user/images/${params.row.profile_img}`
                : `https://api.proxx.in/uploads/user/images/${params.row.b_logo}`
            }
            alt="preview"
          />
        </Box>
      ),
    },
    {
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
      renderCell: (params) => {
        const fieldName = params.row.b_name
          ? "b_name"
          : params.row.full_name
          ? "full_name"
          : "";
        return <span>{params.row[fieldName]}</span>;
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      align: "center",
      flex: 1,
      editable: true,
    },
    {
      field: "phno",
      headerName: "Number",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "Action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
      renderCell: (params) => (
        <Box
          spacing={2}
          direction="row"
          sx={{ display: "flex", gap: 1, alignItems: "center" }}
        >
          <Link to={`/user/${params.id}`}>
            <Box
              sx={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src={eye}
                alt="preview"
              />
            </Box>{" "}
          </Link>
          <Box
            onClick={() => handleConfirm(params.id, -2)}
            sx={{ width: "20px", height: "20px", cursor: "pointer" }}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              src={block}
              alt="preview"
            />
          </Box>
          <Box
            onClick={() => handleConfirm(params.id, -2)}
            sx={{ width: "20px", height: "20px", cursor: "pointer" }}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              src={bin}
              alt="preview"
            />
          </Box>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          height: "calc(100vh - 180px)",
          width: "100%",
          overflow: "auto",
        }}
      >
        {" "}
        <DataGrid
          pagination
          paginationMode="server"
          rows={data}
          columns={columns}
          loading={isLoading}
          page={page}
          pageSize={pageSize}
          rowCount={total_count}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          hideFooter
          disableColumnMenu
          sx={{ border: "0px" }}
        />
      </Box>{" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",

          alignItems: "center",
        }}
      >
        <Button disabled={count == 0} onClick={handleBack}>
          <ArrowBackIosIcon sx={{ color: count === 0 ? "gray" : "#735b79" }} />
        </Button>
        <Typography>Page : {count / 10 + 1}</Typography>
        <Button
          disabled={count / 10 + 1 == parseInt(total_count / 10 + 1)}
          onClick={handleNext}
        >
          <ArrowForwardIosIcon
            sx={{
              color:
                count / 10 + 1 == parseInt(total_count / 10 + 1)
                  ? "gray"
                  : "#735b79",
            }}
          />
        </Button>
        <ConfirmModal
          id={id}
          handleDelete={handleDelete}
          open={open}
          onClose={handleClose}
        />
      </Box>{" "}
    </>
  );
}
