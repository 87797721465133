import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CustomButton from "../Button/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useState } from "react";

export default function BoostTable({ data, loading }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [id, setId] = useState();

  const columns = [
    {
      field: "id",
      headerName: "Sr No.",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      disableColumnMenu: true,
    },
    {
      field: "event_name",
      headerName: "Event Name",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "user_name",
      headerName: "User Name",
      headerAlign: "center",
      align: "center",
      flex: 1,
      editable: true,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      headerAlign: "center",
      align: "center",
      flex: 1,
      editable: true,
      renderCell: (params) => {
        const createdAt = new Date(params.row.start_date);
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(createdAt);
        return <Typography>{formattedDate}</Typography>;
      },
    },
    {
      field: "end_date",
      headerName: "End Date",
      headerAlign: "center",
      align: "center",
      flex: 1,
      editable: true,
      renderCell: (params) => {
        const createdAt = new Date(params.row.end_date);
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(createdAt);
        return <Typography>{formattedDate}</Typography>;
      },
    },
    {
      field: "event_id",
      headerName: "Event ID",
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      headerAlign: "center",
      align: "center",
      flex: 1,
      editable: true,
      renderCell: (params) => {
        const amount = params?.row?.amount;
        return <Typography>${amount}</Typography>;
      },
    },
  ];
  return (
    <Box
      sx={{
        height: "calc(100vh - 230px)",
        width: "100%",
        overflow: "auto",
      }}
    >
      {loading ? (
        <Box sx={{ mt: "20%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <DataGrid
          editMode="false"
          disableSelectionOnClick
          disableRowSelectionOnClick
          rows={data ? data : []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          disableColumnMenu
          pageSizeOptions={[10]}
          sx={{ border: "0px" }}
        />
      )}
    </Box>
  );
}
