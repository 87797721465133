import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import CustomInput from "../../Components/Input/CustomInput";
import { GetServiceList } from "../../Function/Services/GetService";
import { SearchDebouncing } from "../../Function/SearchDebounce/Debouncing3";
import { API } from "../../Function/Config";
import PaymentTable from "../../Components/Tables/PaymentTable";
import { getPaymentList } from "../../Function/Payment/GetPayment";

const Payment = () => {
  const [paymentData, setPaymentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const apiUrl = API.getService;

  const fetchPayment = async () => {
    setLoading(true);
    try {
      let res = await getPaymentList(searchValue);
      setLoading(false);
      setPaymentData(res.r);
      console.log(res);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  console.log("payment" + paymentData);
  
  const handleSearch = async (e) => {
    const v = e.target.value.trim();
    setSearchValue(v);
    try {
      await SearchDebouncing(v, setPaymentData, apiUrl);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPayment();
  }, []);

  return (
    <Box>
      <Navbar title={"Payments List"} />
      {/* <Box sx={{ width: "30%", m: 2 }}>
        <CustomInput
          onChange={handleSearch}
          showSearchIcon
          placeholder="Search"
          sx={{
            bgcolor: "#E9E6E8",
            width: "100%",
            borderRadius: "10px",
            border: "none",
            "& fieldset": {
              border: "none !important",
            },
          }}
        />
      </Box> */}
      <Box m={2}>
        <PaymentTable loading={loading} data={paymentData} />
      </Box>
    </Box>
  );
};

export default Payment;
