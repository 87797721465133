import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { handleAddInterest } from "../../Function/Interest/AddInterest";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CustomInput from "../../Components/Input/CustomInput";
import upload from "../../utils/image/upload.png";
import CustomButton from "../../Components/Button/CustomButton/CustomButton";
import { GetInterest } from "../../Function/Interest/GetInterest";
import ConfirmModal from "../../Components/Modal/ConfirmModal";
import { handleEditInterest } from "../../Function/Interest/EditInterest";
const Interest = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState("");
  const [type, setType] = useState("Add");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      thumb: null,
      interest: "",
      id: null,
    },
    validationSchema: Yup.object({
      interest: Yup.string()
        .trim()
        .required("Interest Title Is Required")
        .test(
          "notOnlySpaces",
          "Interest should not be only blank spaces",
          (value) => value.trim().length > 0
        ),
      thumb: Yup.string().required("Please Upload Image"),
    }),

    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let res = await handleAddInterest(values);
        if (res.s) {
          setType("Edit");
          setSnackbarMessage("Interest Added successfully");
          setSnackbarOpen(true);
          setImagePreview(null);
          handleInterestData();
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        setType("Add");
        resetForm();
      } catch (error) {
        console.log(error);
        setLoading(false); // Set loading state to false in case of an error
      }
    },
  });
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      formik.setFieldValue("thumb", selectedFile);
      const imageURL = URL.createObjectURL(selectedFile);
      setImagePreview(imageURL);
    }
  };
  const onClose = () => {
    setIsModalOpen(false);
  };
  const handleDeleteModal = (i) => {
    setIsModalOpen(true);
    setId(i);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleRemoveImage = () => {
    setImagePreview(null);
    formik.setFieldValue("thumb", null);
  };
  const handleInterestData = async () => {
    setLoading(true);
    try {
      let res = await GetInterest();
      setData(res.r);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      let res = handleEditInterest(id);
      if (res.s) {
        setSnackbarMessage("Interest Deleted successfully");
        setSnackbarOpen(true);
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      onClose();
      handleInterestData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (e) => {
    setType("Edit");
    formik.setValues({ interest: e.intrest, thumb: e.thumb, id: e.id });
    setImagePreview(`https://api.proxx.in/uploads/interest/${e.thumb}`);
  };

  useEffect(() => {
    handleInterestData();
  }, []);
  console.log("values", formik.values);
  return (
    <Box>
      <Navbar title={"Interest"} />
      <ConfirmModal
        id={id}
        setIsModalOpen={setIsModalOpen}
        handleDelete={handleDelete}
        open={isModalOpen}
        onClose={onClose}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success">{snackbarMessage}</Alert>
      </Snackbar>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%", p: 2 }}>
          <Box>
            <Typography textAlign={"left"} fontWeight={600}>
              Add Interest
            </Typography>
            {imagePreview ? (
              <Box
                sx={{
                  borderRadius: "15px",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: "3px",
                  mt: "5px",
                  border: "2px solid gray",
                  mt: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    borderRadius: "15px",
                    p: 2,
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    sx={{
                      width: "130px",
                      height: "130px",
                      m: "auto",
                      position: "relative",
                    }}
                  >
                    <img
                      width={"100%"}
                      height={"100%"}
                      src={imagePreview}
                      alt="preview"
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: "white",
                      }}
                      onClick={handleRemoveImage}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  borderRadius: "15px",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: "3px",
                  mt: "5px",
                  border: "2px solid gray",
                  mt: "10px",
                }}
              >
                <label
                  style={{
                    display: "block",
                    width: "100%",
                    borderRadius: "10px",
                    height: "150px",
                    cursor: "pointer",
                    textAlign: "center",
                    lineHeight: "150px",
                    display: imagePreview ? "flex" : "block",
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        width: "50px",
                        height: "50px",
                        m: "auto",
                      }}
                    >
                      <img
                        width={"100%"}
                        height={"100%"}
                        src={upload}
                        alt="preview"
                      />
                    </Box>
                    <Typography sx={{ mt: "40px" }}>Upload a Photo</Typography>
                  </Box>

                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </label>
              </Box>
            )}
            <Box
              sx={{
                width: "100%",
                textAlign: "left",
                ml: 1,
                boxSizing: "border-box",
              }}
            ></Box>
            <CustomInput
              name="interest"
              onChange={formik.handleChange}
              value={formik.values.interest}
              sx={{ width: "100%", mt: "10px" }}
              placeholder="Enter Interest List"
            />{" "}
            {formik.touched.interest && formik.errors.interest ? (
              <Typography
                sx={{ color: "red", textAlign: "left", fontSize: "14px" }}
              >
                {formik.errors.interest}
              </Typography>
            ) : null}
          </Box>
          <Box sx={{ width: "50%", m: "auto", mt: "10px" }}>
            <CustomButton
              sx={{
                "&:disabled": {
                  color: "#fff",
                  bgcolor: "gray",
                },
              }}
              disabled={loading || !(formik.dirty && formik.isValid)}
              onClick={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
            >
              {type}
            </CustomButton>
          </Box>
        </Box>
        {/* interest list */}
        <Box sx={{ width: "50%", p: 2, maxHeight: "80vh", overflow: "auto" }}>
          <Typography textAlign={"left"} fontWeight={600}>
            Interest List
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box>
              {data?.map((e, i) => (
                <Box
                  sx={{
                    border: "1px solid gray",
                    borderRadius: "10px",
                    textAlign: "left",
                    p: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: "5px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "50%",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title={e.intrest}>
                      <Typography
                        sx={{
                          width: "100px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          ":hover": {
                            whiteSpace: "normal",
                          },
                        }}
                      >
                        {e.intrest}
                      </Typography>
                    </Tooltip>
                    <Box sx={{ height: "40px", width: "100px" }}>
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px",
                        }}
                        src={`https://api.proxx.in/uploads/interest/${e.thumb}`}
                        alt="preview"
                      />
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <CustomButton
                      onClick={() => handleEdit(e)}
                      sx={{
                        bgcolor: "#5B3D63",
                        "&: hover": {
                          bgcolor: "#5B3D63",
                        },
                        transition: "transform 0.3s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.1)",
                        },
                      }}
                    >
                      Edit
                    </CustomButton>
                    <CustomButton
                      onClick={() => handleDeleteModal(e.id)}
                      sx={{
                        bgcolor: "#8B5455",
                        "&: hover": { bgcolor: "#8B5455" },
                        transition: "transform 0.3s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.1)",
                        },
                      }}
                    >
                      Delete
                    </CustomButton>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Interest;
