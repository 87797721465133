import { API, API_GET } from "../Config";

let debounceTimeout;

const SearchDebouncing =async (searchterm, setEventData, apiUrl, date) => {
  try {
    let res = await API_GET(`${apiUrl}?search=${searchterm}&filter=${date}`);
    console.log(res.r);
    setEventData(res.r);
  } catch (error) {
    console.log(error);
  }
};

export { SearchDebouncing };
